import { GET_ENTITY_SUCCESS, GET_ENTITY_FAILURE } from "../actions/Entity"

const InitialState = {
  people: [],
}

export default function entityService(state = InitialState, action) {
  switch (action.type) {
    case GET_ENTITY_SUCCESS(action.entity):
      return {
        ...state,
        busy: false,
        res: true,
        err: false,
        [action.entity]: action.res.data.items
      };
    case GET_ENTITY_FAILURE(action.entity):
      return {
        ...state,
        busy: false,
        res: false,
        err: true,
        [action.entity]: []
      };
    default:
      return state;
  }
}