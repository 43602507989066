import axios from "axios"
import Config from "../config/config.json"

export function getEntity(entity) {
  return (dispatch) => {
    axios
      .get(`${Config.api.endpoint}/${entity}`)
      .then((res) => {
        dispatch(getEntitySuccess(entity, res))
      })
      .catch((err) => {
        dispatch(getEntityFailure(entity, err))
      })
  }
}

export const GET_ENTITY_SUCCESS = (entity) => entity && `GET_ENTITY_${entity.toUpperCase()}_SUCCESS`
export function getEntitySuccess(entity, res) {
  return {
    type:  GET_ENTITY_SUCCESS(entity),
    entity,
    res
  }
}

export const GET_ENTITY_FAILURE = (entity) => entity && `GET_ENTITY_${entity.toUpperCase()}_FAILURE`
export function getEntityFailure(entity, err) {
  return {
    type:  GET_ENTITY_FAILURE(entity),
    entity,
    err
  }
}